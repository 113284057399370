<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    src="https://ebooking.today/merchant_logo/avatar.png"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini"> <v-img :src="imagePath" v-if="logo" /></span>
          <span class="logo-normal">{{ company_name }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-1" />

    <!-- <v-list dense>
      <base-item-group :item="profile" />
    </v-list> -->

    <v-list-item two-line>
      <v-list-item-avatar>
        <img src="https://ebooking.today/merchant_logo/avatar.png" alt="John" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ user }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <div v-if="industry == 'F&B'">
        <template v-for="(item, i) in computedFood">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </div>

      <div v-else-if="industry == 'Services'">
        <template v-for="(item, i) in computedService">
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>

          <base-item v-else :key="`item-${i}`" :item="item" />
        </template>
      </div>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapState } from "vuex";
import axios from "axios";
import { BASEURL } from "@/api/baseurl";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    service: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "../dashboard",
      },
      {
        icon: "mdi-widgets",
        title: "Customer",
        to: "/Dashboard/customer",
      },
      {
        icon: "mdi-image",
        title: "Service",
        to: "/Dashboard/service",
      },
      {
        to: "/Dashboard/branch",
        icon: "mdi-clipboard-outline",
        title: "Branch",
      },
      {
        icon: "mdi-chart-timeline-variant",
        title: "Provider",
        to: "/Dashboard/provider",
      },

       {
        icon: "mdi-message-processing-outline",
        title: "Promotion",
        to: "/Dashboard/blasting",
      },

      {
        icon: "mdi-grid",
        title: "Setting",
        to: "/Dashboard/setting",
      },
    ],
    food: [
      {
        icon: "mdi-view-dashboard",
        title: "Dashboard",
        to: "../dashboard",
      },
      {
        icon: "mdi-widgets",
        title: "Customer",
        to: "/Dashboard/customer",
      },

      {
        icon: "mdi-image",
        title: "Service",
        to: "/Dashboard/service",
      },
      {
        to: "/Dashboard/branch",
        icon: "mdi-clipboard-outline",
        title: "Branch",
      },
            {
        icon: "mdi-message-processing-outline",
        title: "Promotion",
        to: "/Dashboard/blasting",
      },
      {
        icon: "mdi-grid",
        title: "Setting",
        to: "/Dashboard/setting",
      },
    ],
    industry: "",
    company_name: "",
    user: "",
    logo: "",
    company_id: "",
    domain: BASEURL,
  }),

  created() {
    var data = JSON.parse(this.getCookie("login"));
    this.company_id = data.company_id;
    this.user = data.name;
    this.getCompany();
    // this.industry = data.industry;
    // this.company_name = data.company_name;
    // this.logo = data.logo;
  },
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedFood() {
      return this.food.map(this.mapItem);
    },

    computedService() {
      return this.service.map(this.mapItem);
    },
    imagePath() {
      return "https://lkmng.com/appointment/logo/" + this.logo;
      
      //https://ebooking.today/merchant_logo/
      
    },
    profile() {
      return {
        avatar: true,
        group: "",
        title: this.user,
        children: [],
      };
    },
  },

  watch: {
    "$vuetify.breakpoint.smAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    getCompany() {
      const params = new URLSearchParams();
      params.append("read", "done");
      params.append("company_id", this.company_id);

      axios({
        method: "post",
        url: this.domain + "/company/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.company_name = response.data.company[0].name;
            this.logo = response.data.company[0].logo;
            this.industry = response.data.company[0].industry;
          } else {
            console.log("no company found");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },

    getCookie(name) {
      var nameEQ = name + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important
      margin-left: -9px !important
      .v-list-group--sub-group
        display: block !important

    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          padding-right: 9px
          // margin-right: 24px
          // margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
